<template>
  <div class="base-image">
    <div v-if="loading" class="loading-placeholder">
      <div class="gradient image" />
    </div>
    <template v-else>
      <img v-if="!errorTransform" :src="transformedImage" :class="`img-responsive ${imgClasses}`" :alt="alt">
      <div v-else :class="[{'no-img-wrapper': sex}, 'image-placeholder']">
        <template v-if="!sex">
          <svg height="28" width="28" class="t-icon no-img"></svg>
          <p>{{ 'Failed to load image' | translate }}</p>
        </template>
        <template v-else>
          <img
            :src="`${cdn_url}/images/${sex ? sex : 'female'}.png`"
            :alt="id"
          >
        </template>
      </div>
      <slot></slot>
    </template>
  </div>
</template>

<script>
    import BaseImage from '@views/common/BaseImage/base_image'

    export default {
        mixins: [BaseImage],
        props: {
          id: {
            type: String,
            default: ''
          },
          sex: {
            type: String,
            default: null
          }
        },
        data() {
          return {
            cdn_url: window.APP_CONFIG.CDN_URL,
          }
        }
    }
</script>

<style lang="scss" src="./base_image.scss"></style>
import {hasClass} from '@/helpers'

export default {
	name: 'base-menu',
	data () {
		return {

		}
	},
	props: {
		menu: {
			type: Array,
			default: () => {
				return []
			}
		},
		itemClass: {
			type: String,
			default: ''
		}
	},
	methods: {
		handleAction(action_name, e, item) {
			let el = null
			this.$refs.menu_items.forEach((e) => {
				if(hasClass(e.children[0], 'active')) {
					e.children[0].classList.remove('active')
					el = e.children[0]
				}
			})
			
			if(el != e.target) {
				e.target.classList.add('active')
			}

			if(item.action_second_parent && typeof this.$parent.$parent[action_name] == 'function') {
				this.$parent.$parent[action_name](item)
			} else if (typeof this.$parent[action_name] == 'function') {
				this.$parent[action_name](item)
			} else if (typeof this.$root.$children[0][action_name] == 'function') {
				this.$root.$children[0][action_name](item)
			}
		},
		triggerViaId(id) {
			this.menu.find((val,key) => {
				if(id == val.id) {
					this.$refs.menu_items[key].children[0].click()
				}
			})
		},
		setActiveViaId(id) {
			this.menu.find((val,key) => {
				this.$refs.menu_items[key].children[0].classList.remove('active')
				if(id == val.id) {
					this.$refs.menu_items[key].children[0].classList.add('active')
				}
			})
		},
		forceReset() {
			this.$refs.menu_items.forEach((e) => {
				if(hasClass(e.children[0], 'active')) {
					e.children[0].classList.remove('active')
				}
			})
		},
		getBasicRoute(item) {
			if(item.isBasic) return `/${item.route}`
			return { name: item.route, params: item.params }
		}
	},
	mounted(){

	}
}
import CookiesNotificationModal from '@views/common/CookiesNotificationModal'
import CookiesSettingsModal from '@views/common/CookiesSettingsModal'
import CookiesPartnersModal from '@views/common/CookiesPartnersModal'

export default {
  components: {
    CookiesNotificationModal,
    CookiesSettingsModal,
    CookiesPartnersModal
  },
  data() {
    return {
      broadcastedConsent: false
    }
  },
  watch: {
    '$route'(to, from) {
      // don't open the modal on cookies page
      if (window.APP_CONFIG.COOKIE_POPUP && to.name !== 'cookie-policy') {
        this.checkIfCookiesAccepted()
      }
    }
  },
  created() {
    EventBus.$on('open-cookies-settings', this.openCookiesSettings)
    EventBus.$on('cookies-consent-saved', this.cookiesConsentSaved)
    EventBus.$on('open-list-of-partners', this.openCookiesPartners)
    EventBus.$on('open-cookies-notification', this.openCookiesNotification)

  },
  beforeDestroy() {
    EventBus.$off('open-cookies-settings', this.openCookiesSettings)
    EventBus.$off('cookies-consent-saved', this.cookiesConsentSaved)
    EventBus.$off('open-list-of-partners', this.openCookiesPartners)
    EventBus.$off('open-cookies-notification', this.openCookiesNotification)
  },
  methods: {
    openCookiesSettings(options = {}) {
      this.closeModal();
      this.changeModalContent({
        name: 'cookies-settings-modal',
        hasCloseButton: true,
        onClose: () => {},
        alignCenter: true,
        canCloseBackDrop: false,
        layoutType: 'medium',
        extraClasses: 'base-modal-cookies centered',
        ...options
      })
      this.openModal()
    },
    openCookiesNotification() {
      this.closeModal();
      this.changeModalContent({
        name: 'cookies-notification-modal',
        hasCloseButton: false,
        alignCenter: true,
        canCloseBackDrop: false,
        layoutType: 'medium',
        extraClasses: 'base-modal-cookies centered'
      })
      this.openModal()
    },
    openCookiesPartners() {
      this.closeModal();
      this.changeModalContent({
        name: 'cookies-partners-modal',
        hasCloseButton: true,
        onClose: () => { EventBus.$emit('open-cookies-notification') },
        alignCenter: true,
        canCloseBackDrop: false,
        layoutType: 'medium',
        extraClasses: 'base-modal-cookies centered'
      })
      this.openModal()
    },
    cookiesConsentSaved() {
      // if false will broadcast
      this.broadcastedConsent = !window.APP_CONFIG.COOKIE_POPUP;
      this.broadcastCookieConsent();
    },
    broadcastCookieConsent() {
      if (this.broadcastedConsent) {
        return
      }
      this.broadcastedConsent = true;
      if (typeof gtag === 'function') {
        gtag('consent', 'update', {
          'analytics_storage': this.$store.getters['cookies/consentPerformance'] ? 'granted' : 'denied',
          'ad_storage': this.$store.getters['cookies/consentAdvertising'] ? 'granted' : 'denied'
        });
      }
      if (this.$store.getters['cookies/consentPerformance']) {
        window.dataLayer.push({
          'event': 'analytics_storage_on'
        })
      } else {
        window.dataLayer.push({
          'event': 'analytics_storage_off'
        })
      }
      if (this.$store.getters['cookies/consentAdvertising']) {
        window.dataLayer.push({
          'event': 'ad_storage_on'
        })
        gtag('set', 'allow_google_signals', true);
      } else {
        window.dataLayer.push({
          'event': 'ad_storage_off'
        })
        gtag('set', 'ads_data_redaction', true);
        gtag('set', 'allow_google_signals', false);
      }
    },
    checkIfCookiesAccepted() {
      let authenticated = this.$store.getters['auth/isAuthenticated']
      if (!authenticated && !this.$store.getters['cookies/isConsentApproved']) {
        this.openCookiesNotification();
      } else {
        this.broadcastCookieConsent()
      }
    }
  }
}

import Vue from 'vue'
import VueResource from 'vue-resource'
import VueMeta from 'vue-meta'
import VueToast from 'vue-easy-toast'
import VueImg from 'v-img'
import VueNotifications from 'vue-notification'
import Vue2TouchEvents from 'vue2-touch-events'
import TextareaAutosize from 'vue-textarea-autosize'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueTheMask from 'vue-the-mask'
import Echo from 'laravel-echo'
import io from 'socket.io-client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { ClickOutside } from '@theme-views/plugins'
import VueRangeSlider from 'vue-range-component-fixed'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import VueCountdown from '@chenfengyuan/vue-countdown'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper'

import store from '@store'
import {loadTranslations} from '@store/locale';
loadTranslations(window.APP_CONFIG.LOCALE);

import router from '@/routes'
import App from '@theme-views/App'
// import { VueReCaptcha } from 'vue-recaptcha-v3'

//polyfills
import IntersectionObserver from 'intersection-observer'

window.Vue = Vue
window.Vue.config.productionTip = false
window.io = io
window.EventBus = new Vue()
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.origin,
    auth: {
        headers: {
            'Authorization': (!store.getters['auth/isAuthenticated']) ? '' : 'Bearer ' + store.getters['auth/token']
        }
    }
})

/**
 * Clickoutside directive
 *
 */
Vue.use(ClickOutside)
/**
 * Vue range slider
 *
 */
Vue.use(VueRangeSlider)
/**
 * Vue overlay scrollbars
 *
 */
Vue.component('OverlayScrollbars', OverlayScrollbarsComponent)
/**
 * Vue countdown
 *
 */
Vue.component('countdown', VueCountdown);

Vue.use(VueMeta)
Vue.use(VueResource)
Vue.use(VueToast)
Vue.use(VueImg)
Vue.use(VueNotifications)
Vue.use(Vue2TouchEvents, {
    disableClick: false,
    touchClass: '',
    tapTolerance: 50,
    swipeTolerance: 100,
    longTapTimeInterval: 200
})
Vue.use(TextareaAutosize)
Vue.use(VueFriendlyIframe)
Vue.use(VueTheMask)

const is_dev = process.env.NODE_ENV !== 'production'
// if(!is_dev) {
//     Vue.use(VueReCaptcha, {
//         siteKey: window.APP_CONFIG.CAPTCHA_KEY_V3,
//         loaderOptions: {
//             autoHideBadge: true
//         }
//     })
// }

if(window.APP_CONFIG.ENABLE_BUGSNAG) {
    Bugsnag.start({
        apiKey: '75c25b36f0bda12073cf83367e2cf4c6',
        plugins: [new BugsnagPluginVue(Vue)]
    })
}

Vue.http.headers.common['X-CSRF-TOKEN'] = document.getElementsByName('csrf-token')[0].getAttribute('content')
Vue.http.headers.common['X-Socket-ID'] = window.Echo.socketId()
Vue.http.headers.common['Authorization'] = (!store.getters['auth/isAuthenticated']) ? '' : 'Bearer ' + store.getters['auth/token']

Vue.http.options.root = process.env.MIX_APP_URL ? process.env.MIX_APP_URL : window.location.origin

Vue.http.interceptors.push((request, next) => {

    let sd_config = store.getters['cookies/getSdConfig'];

    if(request.method == 'GET') {
        request.params = {...request.params, ...sd_config}
    }

    if(request.method == 'POST') {
        if(request.body instanceof FormData) {
            for(var key in sd_config) {
                request.body.append(key, sd_config[key])
            }
        } else {
            request.body = {...request.body, ...sd_config}
        }
    }

    next((response) => {
        let metadata = response.data.metadata
        if (metadata) {
            store.commit('auth/setCredits', metadata.credits)
            const endTrial = store.getters['auth/isOnTrial'] && !store.getters['auth/trialEnded'] && metadata.active;
            store.commit('auth/updateProfile', {active: metadata.active})
            store.commit('auth/setSubscriptionType', metadata.subscription_type)
            store.commit('auth/setPremium', metadata.premium)
            store.commit('auth/setPremiumDaysRemaining', metadata.premium_days_remaining)
            store.commit('auth/setUnreadMessages', metadata.unread_messages)
            store.commit('auth/setUnreadNotifications', metadata.unread_notifications)
            if (endTrial) {
                store.dispatch('trial/remove')
            }
        }

        if (response.data.error === 'user_not_found') {
            store.dispatch('auth/removeAuth')
            router.push({name: 'home', params: {fromRemoveAuth: true}})
        }

        if ([400, 401, 403].indexOf(response.status) !== -1) {
            let res_code = (typeof response.data[0] !== 'undefined') ? response.data[0] : ''
            res_code = (typeof response.data.error !== 'undefined') ? response.data.error : res_code
            if (['token_invalid', 'token_expired', 'token_absent', 'token_blacklisted'].indexOf(res_code) !== -1) {
                store.dispatch('auth/removeAuth')
                router.push({name: 'home', params: {fromRemoveAuth: true}})
            }
        }

    })
})

/*  
    Scrolling beforeEach
    template 3 has delay for transitions 
*/
import { templateCustomBeforeEach } from '@theme-views'

router.beforeEach((to, from, next) => {
    templateCustomBeforeEach()
    next()

    store.dispatch('pub/allowLeave', false)
    store.dispatch('cookies/saveSdConfig', to.query)

    let isAuthenticated = store.getters['auth/isAuthenticated']
    if (isAuthenticated) {
        if (store.getters['cookies/hasORegister']) {
            store.dispatch('cookies/setORegister', null)
        }
        if (store.getters['cookies/hasOUser']) {
            store.dispatch('cookies/setOUser', null)
        }
    } else {
        window.Echo.disconnect()
        store.commit('payment/setDiscounts', [])
        store.commit('payment/setOpenDiscountPopup', false)
        store.commit('payment/setCloseButtonDiscountPopup', false)
    }

    if (['user-credits', 'user-premium'].indexOf(to.name) > -1 && store.getters['auth/isOnTrial']) {
        store.dispatch('trial/end', (params) => {
            router.push({name: 'user-activate-profile', params})
        })
        next({name: 'user-home'})
    }

    if(to.query.token) {
        store.dispatch('auth/autoLogin', {
            params: {
                token: to.query.token
            }, success_cb(response) {
                if (to.query.trial && to.query.key) {
                    this.$store.dispatch('trial/start', {
                        token: to.query.token,
                        trial: to.query.trial,
                        key: to.query.key,
                        callback: (params) => router.push({name: 'user-activate-profile', params})
                    })
                }
                store.dispatch('profile/populateProfiles')
                store.dispatch('pub/getFormValues')
                store.dispatch('message/getIcebreakers')
                if (to.query.google_signup) {
                    next({name: 'user-profile', params: {update: new Date().getTime()}})
                } else if (to.name == 'home') {
                    next({name: 'user' + ( window.innerWidth > 1199 ? '' : '-mobile') + '-home'})
                } else {
                    let query = {}
                    if (to.query.icebreaker) {
                        query = {icebreaker: to.query.icebreaker};
                    }
                    next({path: to.path, query})
                }
            }, error_cb(response) {
                if (response.data.error === 'deleted') {
                    next({
                        name: 'account-deleted', params: {
                            status: 'success',
                            redirect: response.data.redirect || null
                        }
                    })
                } else if (from.name == 'home') {
                    next()
                } else {
                    next({path: '/'})
                }
            }
        })
    }

    if(!from.query.token && !to.query.token && from.matched.length && from.fullPath !== to.fullPath && from.name !== to.name) {
        const fullPath = window.location.origin + from.fullPath
        store.commit('pub/setRouteFrom', fullPath)
        //store.dispatch('cookies/saveNavHistory', to.fullPath)
    }

    store.commit('pub/setRoutePrev', from)

    if (to.meta.requiresAuth && !isAuthenticated) {
        next({ path: '/' })
    } else if (to.meta.requiresGuest && isAuthenticated) {
        next({ name: 'user' + ( window.innerWidth > 1199 ? '' : '-mobile') + '-home' })
    } else {
        next()
    }
})

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString().toLowerCase()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('lowercase', function (value) {
    return value.toString().toLowerCase()
})

const app = new Vue({
	store,
	el: '#app',
	router,
	template: '<App/>',
	components: { App }
})

import BaseMenu from '@views/common/BaseMenu'
import {checkNull} from '@/helpers'

export default {
    name: 'main-footer',
    data() {
        return {
            icdn_url: window.APP_CONFIG.ICDN_URL,
            cdn_url: window.APP_CONFIG.CDN_URL,
            links: window.APP_CONFIG.LINKS,
            content: window.APP_CONFIG.LANDING_PAGE_CONTENT,
            site_name: window.APP_CONFIG.DOMAIN_NAME,
            hide_address: window.APP_CONFIG.HIDE_ADDRESS,
            footer_address: window.APP_CONFIG.FOOTER_ADDRESS,
            footer_address_is_long: false,
            sub_menu_1: [
                { name: 'Support', route: 'support' },
                { name: 'Blog', route: 'home' },
                { name: 'Affiliates', route: window.APP_CONFIG.AFFILIATE_LINK, isExternal: true},
            ],
            sub_menu_2: [
                { name: 'Cookies', route: 'cookie-policy' },
                { name: 'Privacy', route: 'custom-page' },
                { name: 'FAQ', route: 'faq' },
                { name: 'Terms', route: 'custom-page' },
            ],
            current_year: (new Date()).getFullYear(),
            origin: window.location.origin,
        }
    },
    methods: {
        limitLinkText(text) {
            return (text.length > 22) ? text.substring(0, 20)+'...' : text
        },
        init_links() {
            this.sub_menu_2[1] = { name: 'Privacy', route: this.$t('privacy'), isBasic: true  }
            this.sub_menu_2[3] = { name: 'Terms', route: this.$t('terms-and-conditions'), isBasic: true }

            if(!Array.isArray(this.links)) {
                return;
            }
            let usNoticeIndex = null, usNoticeLink = null;
            this.links.forEach(val => {
                if(val.name) {
                    switch(val.name) {
                        case 'blog': this.sub_menu_1[1] = { name: 'Blog', route: val.url , isExternal: true }; break;
                        case 'affiliates': this.sub_menu_1[2] = { name: 'Affiliates', route: window.APP_CONFIG.AFFILIATE_LINK || val.url , isExternal: true, hide: checkNull(val.hide) }; break;
                        case 'about-us': this.sub_menu_1.push( { name: 'About Us', route: val.url, isBasic: true }); break;
                        case 'opinions': this.sub_menu_2.push( { name: 'Opinions', route: val.url, isBasic: true }); break;
                        case 'us-notice':
                            this.sub_menu_2.push( { name: val.label || '18 U.S.C. 2257', route: val.url, isExternal: true, hide: false, title: val.title });
                            usNoticeIndex = this.sub_menu_2.length - 1;
                            break;
                        default: let item = { name: val.name, route: val.url, isBasic: true };
                                if(this.sub_menu_1.length > this.sub_menu_2.length) {
                                    this.sub_menu_2.push(item)
                                } else {
                                    this.sub_menu_1.push(item)
                                }
                                break;
                    }
                }
            })

            if (!this.sub_menu_1[1].isExternal) {
                this.sub_menu_1[1].hide = true
            }

            //move affiliates and terms
            this.sub_menu_1.push(this.sub_menu_1.splice(2, 1)[0])
            if (usNoticeIndex) {
                // us notice link should be last
                usNoticeLink = this.sub_menu_2.splice(usNoticeIndex, 1)[0];
            }
            this.sub_menu_2.push(this.sub_menu_2.splice(3, 1)[0])
            if (usNoticeIndex && usNoticeLink) {
                this.sub_menu_2.push(usNoticeLink)
            }
        },
        check_footer_image() {
            const url = this.footer_address
            const filename = url.substring(url.lastIndexOf('/')+1)
            console.log('check footer image', this.isAuthenticated)
            if(!this.isAuthenticated) {
                this.footer_address_is_long = true
                this.footer_address = url.replace(filename, filename.slice(0,-4)+'-public.png')
            }
        },
    },
    computed: {
        city_pages() {
            return this.$store.getters['pub/cityPages']
        },
        isAuthenticated() {
            return this.$store.getters['auth/isAuthenticated']
        },
        footerText() {
            return this.content.footer_text ? this.content.footer_text : this.$t('Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.') 
        }
    },
    created() {
        this.init_links()
        this.check_footer_image()
    },
    components: {
        BaseMenu
    }
}

import paymentService from '../../services/payment'
import {safeRedirect} from "@/helpers";

export default {
	namespaced: true,
	state: {
		gateways: [],
		payment_types: [],
		payment_started: null,
		discounts: [],
		open_discount_popup: false,
		close_button_discount_popup: false
	},
	getters: {
		gateways(state) {
			return state.gateways
		},
		paymentTypes(state) {
			return state.payment_types
		},
		paymentStarted(state) {
			return state.payment_started
		},
		discounts(state) {
			return state.discounts
		},
		openDiscountPopUp(state) {
			return state.open_discount_popup
		},
		closeButtonDiscountPopup(state) {
			return state.close_button_discount_popup
		}
	},
	mutations: {
		setGateWays(state, payload) {
			state.gateways = payload
		},
		setPaymentTypes(state, payload) {
			state.payment_types = payload
		},
		setPaymentStarted(state, payload) {
			state.payment_started = payload
		},
		setDiscounts(state, payload) {
			state.discounts = payload
		},
		setOpenDiscountPopup(state, payload) {
			state.open_discount_popup = payload
		},
		setCloseButtonDiscountPopup(state, payload) {
			state.close_button_discount_popup = payload
		}
	},
	actions: {
		getTotalOnlineUsers(context, paylaod) {
			return paymentService.getTotalOnlineUsers()
		},
		getPremiumProducts(context, paylaod) {
			return paymentService.getPremiumProducts()
		},
		getDeletionProduct(context, paylaod) {
			return paymentService.getDeletionProduct()
		},
		getInactiveUserProduct(context, paylaod) {
			return paymentService.getInactiveUserProduct()
		},
		getGateWays(context, paylaod) {
			return new Promise((resolve, reject) => {
				paymentService.getProducts()
					.then(response => {
						let gateways = Object.assign({}, response.data)
						context.commit('setGateWays', {products: gateways.products})
						context.commit('setPaymentTypes', gateways.payment_types)
						resolve(response)
					}, err => reject(err))
			})
		},
		startPayment(context, {product_id, payment_method_id, params, success_cb, error_cb}) {
			if (context.getters.paymentStarted) {
				return
			}
			context.commit('setPaymentStarted', payment_method_id || 1)
			paymentService.startPayment({product_id, payment_method_id, params})
				.then(res => {
					if(typeof success_cb == 'function') success_cb(res)
					if(res.data.url && !res.data.modal) {
						safeRedirect(res.data.url)
						context.commit('setPaymentStarted', null)
					} else {
						context.commit('setPaymentStarted', null)
					}
				}, res => {
					context.commit('setPaymentStarted', null)
					if(typeof error_cb == 'function') error_cb(res)
				})
		},
		getNextPaymentMethod(context, payload) {
			return paymentService.getNextPaymentMethod(payload)
		},
		validateCardNumber(context, payload) {
			return paymentService.validateCardNumber(payload)
		},
		makeCardinityPayment(context, payload) {
			return paymentService.makeCardinityPayment(payload)
		},
		checkPayment(context, id) {
			return paymentService.checkPayment(id)
		},
		idealIssuers(context, id) {
			return paymentService.idealIssuers(id)
		},
		allowCancellation(context) {
			return paymentService.allowCancellation()
		},
		cancelSubscription(context) {
			return paymentService.cancelSubscription()
		},
		getSeasonalDiscount(context) {
			return new Promise((resolve, reject) => {
				paymentService.getSeasonalDiscount()
					.then(res => {
						if(res.data[0] === 'not_available') {
							reject(res)
						} else {
							context.commit('setDiscounts', res.data)
							resolve(res)
						}
					}, err => {
						reject(err)
					})
			})
		}
	}
}

export default {
  data() {
    return {
      cookieConsentSelected: ['technical'],
      cookieConsentBase: 'technical',
      cookieConsentAll: [
        {key: 'technical', label: 'Technical (Mandatory)', show_description: false,  description: 'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.'},
        {key: 'performance', label: 'Performance (Monitor website performance)', show_description: false, description: 'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.'},
        {key: 'functional', label: 'Functional (User experience improvement)', show_description: false, description: 'These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.'},
        {key: 'advertising', label: 'Advertising (Marketing measurement)', show_description: false, description: 'These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.'},
      ]
    }
  },
  computed: {
    showDesktop() {
      return this.$root.$children[0].width > 1199
    },
    cookie_policy_link() {
      let route = this.$router.resolve({
        name: 'cookie-policy'
      })
      return route.href;
    }
  },
  watch: {
    cookieConsentSelected(newVal, oldVal) {
      if (!this.cookieConsentSelected.includes(this.cookieConsentBase)) {
        this.cookieConsentSelected.unshift(this.cookieConsentBase);
      }
    }
  },
  mounted() {
    this.cookieConsentSelected = this.$store.getters['cookies/getConsent'].map(obj => obj)
  },
  methods: {
    acceptAllCookies() {
      this.cookieConsentSelected = this.cookieConsentAll.map(consent => consent.key);
      this.saveSelection();
    },
    rejectAllCookies() {
      this.cookieConsentSelected = [this.cookieConsentBase];
      this.saveSelection();
    },
    saveSelection() {
      this.saveCookiesConsent();
      this.closeCookieSettings();
    },
    closeCookieSettings() {
      setTimeout(() => {
        return this.$emit('close-modal')
      }, 1000)
    },
    saveCookiesConsent() {
      this.$store.dispatch('cookies/saveConsent', this.cookieConsentSelected);
      EventBus.$emit('cookies-consent-saved');
    },
    openCookiesSettings() {
      EventBus.$emit('open-cookies-settings', {onClose: () => { EventBus.$emit('open-cookies-notification') }});
    },
    openListOfPartners() {
      EventBus.$emit('open-list-of-partners');
    },
    translationWithLink() {
      let translated = this.$t('You will be able to change your set up at any time from our :cookies_policy page');
      return translated.replace(':cookies_policy', '<a href="' + this.cookie_policy_link + '">' + this.$t('cookie policy') + '</a>');
    },
    toggleDescription(consent) {
      consent.show_description = !consent.show_description
    }
  }
}
